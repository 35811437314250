<template>
  <div>
    <!-- <b-row>
      <b-col>
        <statistik-belanja></statistik-belanja>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel-m-belanja :rs_data="rs_data" @DeleteData=DeleteData @EditData=EditData></tabel-m-belanja>
        </b-card>
      </b-col>
    </b-row>
    <add-pagu-anggaran :rs_dinas="rs_dinas" @SimpanPaguAnggaran=SimpanPaguAnggaran></add-pagu-anggaran>
    <edit-pagu :rs_detail=rs_detail @UpdatePaguAnggaran=UpdatePaguAnggaran></edit-pagu>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCol, BRow, BCard
} from 'bootstrap-vue'
import TabelMBelanja from './component/TabelMBelanja.vue'
import StatistikBelanja from './component/StatistikBelanja.vue'
import AddPaguAnggaran from './component/AddPaguAnggaran.vue'
import EditPagu from './component/EditPagu.vue'
export default {
  components: {
    BTabs, EditPagu,
    BTab, BCol, BRow, BCard,
    TabelMBelanja,
    StatistikBelanja, AddPaguAnggaran,
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      rs_dinas: [],
      rs_detail: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.load_dinas()
  },
  methods: {
    async UpdatePaguAnggaran(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pagu/belanja/update',
        data: {
          pagu_anggaran: val.pagu_anggaran,
          id_pagu_belanja: val.id_pagu_belanja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', " ", response.data.message);
          self.load_data();
          self.load_dinas();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    EditData(val) {
      this.rs_detail = val
    },
    async DeleteData(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pagu/belanja/delete',
        data: {
          id_pagu_belanja: val.id_pagu_belanja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', " ", response.data.message);
          self.load_data();
          self.load_dinas();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async SimpanPaguAnggaran(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pagu/belanja/insert',
        data: {
          id_master_dinas: input.id_master_dinas.id_master_dinas,
          pagu_anggaran: input.pagu_anggaran
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', " ", response.data.message);
          self.load_data();
          self.load_dinas();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pagu/belanja/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_dinas() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pagu/belanja/load_dinas',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_dinas = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>