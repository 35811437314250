<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="Tambah Pagu Belanja SKPD" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group label="Nama SKPD" label-for="username">
            <validation-provider #default="{ errors }" rules="required" name="Nama SKPD">
              <v-select v-model="input.id_master_dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nama_dinas" :state="errors.length > 0 ? false : null" :options="rs_dinas" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Pagu Pendapatan  Rp.  " label-for="Pagu Anggran">
            <validation-provider #default="{ errors }" rules="required" name="First Name">
              <currency-input v-model="input.pagu_anggaran" class="form-control"
                :state="errors.length > 0 ? false : null"></currency-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <b-row>
            <b-col cols="12">
              <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
                <feather-icon icon="SaveIcon" size='15' /> Simpan Pagu Belanja
              </b-button>

              <b-button variant="warning" type="submit" @click="close()" size="sm" class="ml-1">
                <feather-icon icon="XIcon" size='15' /> Keluar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from '@/views/input/data_kontrak/componet/CurrencyInput.vue'

export default {
  components: {
    BButton, vSelect, CurrencyInput,
    BModal, ValidationProvider, ValidationObserver,
    BAlert, BFormGroup, BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      input: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["SimpanPaguAnggaran"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('SimpanPaguAnggaran', this.input)
          this.close();
          this.input = {}
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    rs_dinas: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
